export const firebaseConfig = {
  admin: {
    apiKey: 'AIzaSyAMS7Ada64cK5DfTagI3_skL7BM4QTVJAY',
    authDomain: 'sportsplannr-prod.firebaseapp.com',
    projectId: 'sportsplannr-prod',
    storageBucket: 'sportsplannr-prod.appspot.com',
    messagingSenderId: '75117318425',
    appId: '1:75117318425:web:fb650c51a13745fbbc3588',
    measurementId: 'G-VFQ4K67J2X',
  },
  user: {
    apiKey: 'AIzaSyAMS7Ada64cK5DfTagI3_skL7BM4QTVJAY',
    authDomain: 'sportsplannr-prod.firebaseapp.com',
    projectId: 'sportsplannr-prod',
    storageBucket: 'sportsplannr-prod.appspot.com',
    messagingSenderId: '75117318425',
    appId: '1:75117318425:web:93083c2e028b37d6bc3588',
    measurementId: 'G-XXCPXHHNTM',
  },
  superAdmin: {
    apiKey: 'AIzaSyAMS7Ada64cK5DfTagI3_skL7BM4QTVJAY',
    authDomain: 'sportsplannr-prod.firebaseapp.com',
    projectId: 'sportsplannr-prod',
    storageBucket: 'sportsplannr-prod.appspot.com',
    messagingSenderId: '75117318425',
    appId: '1:75117318425:web:4c589313709ae440bc3588',
    measurementId: 'G-R1HDTSLT01',
  },
  auth: {
    apiKey: 'AIzaSyAMS7Ada64cK5DfTagI3_skL7BM4QTVJAY',
    authDomain: 'sportsplannr-prod.firebaseapp.com',
    projectId: 'sportsplannr-prod',
    storageBucket: 'sportsplannr-prod.appspot.com',
    messagingSenderId: '75117318425',
    appId: '1:75117318425:web:ab4e7a311e6f69bcbc3588',
    measurementId: 'G-1K4C28QJ8X',
  },
  site: {
    apiKey: "AIzaSyAMS7Ada64cK5DfTagI3_skL7BM4QTVJAY",
    authDomain: "auth.swimplannr.be",
    projectId: "sportsplannr-prod",
    storageBucket: "sportsplannr-prod.appspot.com",
    messagingSenderId: "75117318425",
    appId: "1:75117318425:web:f5bc992a99b937c0bc3588",
    measurementId: "G-8VD3LQWM4E"
  }
};

export const algoliaConfig = {
  appId: 'L3FVY4DZYO',
  searchKey: 'e4ee8852b511ea565a75b689f46a229c',
  businessId: null
};

export const reCaptchaConfig = {
  siteKey: '6LcLsbwmAAAAAPt-P1ZohOYNjmjcUp1LGbV_sw_P',
};

export const sentryConfig = {
  adminDsn:
    'https://3e07f354179ca54d61ac691c8aabca4d@o4505589408071680.ingest.sentry.io/4505589412003840',
  userDsn:
    'https://4d8994bc7a20bece06cdc2f85dca951e@o4505589408071680.ingest.sentry.io/4505589556379648',
  functionsDsn:
    'https://d1c7219e41b3bdd5dce1bbce2ea89853@o4505589408071680.ingest.us.sentry.io/4506552573231104',
};

export const banner = '';

export const authUrl = 'https://auth.proswimlab.be';
export const superAdmin = 'https://superadmin.swimplannr.be';
export const admin = 'https://admin.proswimlab.be';
export const user = 'https://platform.proswimlab.be';

export const businessId = "psl"